.card {
    margin: 0 10px;
    border-radius: 4px;
  }
 .recently-card .card-img-top {
    width: 100%;
    height: 190px;
    object-fit: fill;
    border-radius: 4px;
  }


  .slick-slide {
    padding: 0 10px;
  }
  .comicName{
    margin:10px
  }

  @media (max-width: 320px) {
    .recently-card .card-img-top {
      width: 100%;
      height: 190px;
      object-fit: fill;
    }
  }

  @media (max-width: 700px) {
    .recently_added_container{
      margin-top: 14%;
    }
  
  }