@import url("https://fonts.googleapis.com/css2?family=League+Spartan:wght@100..900&display=swap");
.slider-container {
  width: 100%;
  margin-bottom: 5%;
  height: 500px;
}

.slick-slide.slick-active.slick-current {
  padding: 0px !important;
}
.slick-dots{
  bottom: -10px !important;
}
.slick-dots li button:before {
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  opacity: 0.65;
  color: rgb(255, 255, 255);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  opacity: 0.95;
  color: #da8ee7;
}
.card-body1 {
  position: relative;
  width: 100%;
  /* background: url("https://mangadex.org/covers/17eebcc7-d993-446c-84be-c222bfb27794/a2b06f8e-c8e4-4b8e-b4be-467c6378f3aa.jpg.256.jpg"); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0px 30%;
  height: 500px;
  background-color: rgba(0, 0, 0, 0.434);
  background-blend-mode: multiply;
}
.card-body1::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: 0 10px 125px 168px #191a1c;
  z-index: 1;
  backdrop-filter: contrast(0.73);
}
/* .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    filter: brightness(0.5); 
} */

.card_body1_img {
  position: absolute;
  z-index: 20; /* Ensure image is on top of overlay */
  /* width: 15vw;
    height: 20vw; */
  width: 250px;
  height: 300px;
  /* height: auto; */
  border-radius: 5px;
  left: 1vw;
  bottom: 50px;
  max-width: 80%; /* Make image responsive */
  /* background: url("https://mangadex.org/covers/17eebcc7-d993-446c-84be-c222bfb27794/a2b06f8e-c8e4-4b8e-b4be-467c6378f3aa.jpg.256.jpg"); */
  background-size: cover;
  /* background-color: #fff; */
  background-repeat: no-repeat;
  top: 25%;
}

@media (max-width: 480px) {
  .card-body1 {
    height: 330px !important;
  }
  .card_body1_img {
    width: 150px; /* Adjust to the desired width for 480px screen */
    height: 200px; /* Maintain aspect ratio */
    bottom: 37px;
    top: 25%;
  }
  .card_body1_content_container {
    position: absolute;
    left: 170px !important;
    top: 25% !important;
    width: 36% !important;
  }
  .card_body1_content_container .card_body1_title {
    font-size: 1em;
  }
  .genre_title {
    padding: 0;
    padding-left: 2px !important;
    padding-right: 2px !important;
    font-size: 0.6em !important;
  }
  
  .card_body1_content_container .card_body1_description {
   display: none;
  }
  .card_body1_img_title1 {
    top: 5.5% !important;
    font-size: 1.5em !important;
  }

  .slider-container {
    width: 100%;
    margin-bottom: 6%;
    height: 375px;
  }
}

.card_body1_content_container {
  position: absolute;
  left: 300px;
  top: 24%;
  width: 76%;
  /* background-color: #191A1C; */
  /* margin-left: 20%; */
}

.card_body1_title {
  font-size: 3.5em;
  color: white;
  font-family: poppins;
  font-weight: 600;
  text-align: start;
}
.card_body1_description_container {
  width: 100%;
  /* height: 200px; Adjust the height if needed based on the text and line height */
  overflow: hidden;
  position: relative;
}

.card_body1_description {
  font-size: 1.2em;
  color: white;
  font-family: poppins;
  font-weight: 400;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.7;
  max-height: calc(4 * 1.5em); /* 4 lines times the line-height */
  padding: 10px; /* Optional: Adjust based on your design */
  box-sizing: border-box;
  text-align: justify;
}

.card_body1_img_title1 {
  z-index: 1;
  font-size: 2em;
  color: white;
  font-family: "League Spartan", sans-serif;
  position: absolute;
  left: 2%;
  top: 9%;
  font-weight: 500;
  text-align: left;
}

@media (min-width:768px) and (max-width:1024px){
  .card_body1_img_title1 {
    top:7%
  }
}
@media (min-width:1024px){
  .card_body1_img_title1 {
    top:9%
  }
}