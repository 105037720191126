.showLibrary {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(125px, 1fr));
    width: 85%;
    margin: 0px auto;
    column-gap: 20px;
    gap: 20px;
  }
  
  .showLibrary img {
    width: 100%;
    display: block;
    object-fit: fill;
    height: 100%; 
  }
  .grid-item {
    position: relative;
    overflow: hidden;
    width: 136px;
  }
  .text-overlay {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    text-align: center;
    padding: 10px;
    box-sizing: border-box;
  }
  .grid-item::after {
    content: attr(data-text);
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #222;
    color: white;
    font-weight: 700;
    text-align: start;
    padding-left: 7px;
    box-sizing: border-box;
    text-transform: capitalize;
    padding-top: 2px;
    padding-bottom: 2px;
  }
  