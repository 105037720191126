.showLibrary {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(125px, 1fr));
  width: 85%;
  margin: 0px auto;
  column-gap: 20px;
  gap: 20px;
}

.showLibrary img {
  width: 100%;
  display: block;
  object-fit: fill;
  height: 100%; 
}
.grid-item {
  position: relative;
  overflow: hidden;
  width: 136px;
}
.text-overlay {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
}
.grid-item::after {
  content: attr(data-text);
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #4c4c4c;
    color: white;
    font-weight: 700;
    text-align: start;
    padding-left: 7px;
    box-sizing: border-box;
    /* padding-bottom: 7px; */
    text-transform: capitalize;
    padding-top: 2px;
    padding-bottom: 2px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.7;
    max-height: calc(4* 1.5em);
    padding: 10px;
    box-sizing: border-box;
    text-align: justify;
}

.signin_btn{
  background-color: #f9f7fa;
  width: 150px;
  height: 50px;
  color: black;
  border: 0px;
  margin-right: 10px;
  border-radius: 10px;
  font-family: poppins;
}