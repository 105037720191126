.css-g2z002-MuiSvgIcon-root-MuiPaginationItem-icon{
    color:white
}

button.MuiButtonBase-root{
    color: white;
}

.pagination{
    width: 95%;
    margin: 25px auto;
}