.intro_ad {
  width: 336px;
  height: 280px;
  /* background-color: antiquewhite; */
  /* margin-bottom: 10%; */
  position: relative;
}
/* .intopage_main_container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #222222;
  min-height: 103vh !important;
} */
.intopage_main_container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #222222;
  min-height: 140vh; /* Use 100vh instead of 103vh unless specific design reasons */
  margin: 0; /* Reset margin to ensure container reaches edges */
  padding: 0; /* Reset padding to ensure container starts from the edge */
}

.intopage_main_container1 {
  height: 100vh !important;
}

.dashboard_ad_container1 {
  width: 90%;
  height: 280px;
  min-width: 336px;
}
.dashboard_ad_container2 {
  width: 90%;
  height: 280px;
}

.comic_ad_container1 {
  margin-top: 3%;
  width: 90%;
  height: 280px;
  min-width: 336px;
}

.comic_ad_container2 {
  width: 90%;
  height: 280px;
  margin-top: 1.5rem;
}
.comic_view_ad_container2 {
  width: 80%;
  height: 280px;
  margin-left: 1.5%;
  margin-top: 1.5rem;
  min-width: 336px;
}
.comic_view_ad_container3 {
  width: 80%;
  height: 280px;
  margin-top: 60px !important;
  margin-left: 1.5%;
  min-width: 336px;
}
.button {
  /* background-color: #da8ee7 ; */
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid #da8ee7;
  color: #da8ee7;
}
.button:focus,
.button:active {
  background-color: #6f137f;
  border: 1px solid #6f137f;
  color: white;
  outline: none;
  -webkit-outline: none;/*  Safari-specific */
  -webkit-background-color: #6f137f; /*Safari-specific*/
}

.intro_logo {
  width: 150px;
  height: 150px;
  position: relative;
  margin-top: -30px;
}

.intro_txt1 {
  font-size: 2em;
  margin-top: -10%;
  font-family: poppins;
  font-weight: 600;
  position: relative;
  color: rgb(187 94 241);
}

.intro_other_link {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 5%;
}

@media (max-width: 380px) {
  .comic_ad_container1 {
    margin-left: -6px;
  }
}
