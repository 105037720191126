.dt-length {
    text-align: start;
}
div.dt-container div.dt-paging {
    margin: 20px;
    text-align: end;
}
.add_genre_btn{
    position: absolute;
    left: 0.9vw;
}
