
.viewer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .viewer img {
    max-width: 100%;
    max-height: 100vh;
  }
  /* User_Comic_Viewer.css */
  /* User_Comic_Viewer.css */

/* .comic-viewer-container {
  display: flex;
}

.comic-viewer {
  flex-grow: 1;
}

.double-page-view {
  display: flex;
  justify-content: center;
  align-items: center;
}

.double-page-view img {
  margin: 0 10px;
  max-width: 45%;
}

.long-strip-view {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.long-strip-view img {
  margin-bottom: 10px;
  max-width: 100%;
}

.multiple-pages-view {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.multiple-pages-view .page {
  margin: 10px;
  max-width: 45%;
}

.comic-viewer-controls {
  margin-top: 20px;
  text-align: center;
}

.comic-viewer-controls button {
  margin: 0 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.comic-viewer-controls button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.comic-viewer-sidebar {
  width: 200px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.comic-viewer-sidebar button {
  margin-bottom: 10px;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
} */


/* 
.comic-viewer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.double-page-view, .multiple-pages-view, .long-strip-view {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.double-page {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.double-page img, .multiple-pages-view img, .long-strip-view img {
  width: 45%;
  height: auto;
}

.full-width-view .image-gallery-slide img {
  width: 100%;
  height: auto;
}

.view-mode-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.view-mode-buttons button {
  padding: 10px 20px;
  border: none;
  background-color: #da8ee7;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.view-mode-buttons button:hover {
  background-color: #0056b3;
}

.toggle-sidebar {
  margin-top: 20px;
  padding: 10px 20px;
  border: none;
  background-color: #da8ee7;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.sidebar {
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  width: 250px;
  background-color: #f4f4f4;
  box-shadow: -2px 0 5px rgba(0,0,0,0.5);
  transform: translateX(100%);
  transition: transform 0.3s ease;
  padding: 20px;
}

.sidebar.open {
  transform: translateX(0);
}

@media (max-width: 768px) {
  .sidebar {
    width: 100%;
  }
} */

/* 
.comic-viewer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: margin-right 0.3s ease;
}

.comic-viewer-container.with-sidebar {
  margin-right: 250px;
}

.double-page-view, .multiple-pages-view, .long-strip-view, .full-width-view {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  justify-content: center;
}

.double-page {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.double-page img, .multiple-pages-view img, .long-strip-view img {
  width: 45%;
  height: auto;
}

.long-strip-view {
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.long-strip-page img, .full-width-page img {
  width: 100%;
  height: auto;
}

.full-width-view {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.full-width-page {
  width: 100%;
  margin-bottom: 10px;
}

.view-mode-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 100px;
}

.view-mode-buttons button {
  padding: 10px 20px;
  border: none;
  background-color: #da8ee7;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.view-mode-buttons button:hover {
  background-color: #0056b3;
}

.toggle-sidebar {
  margin-top: 20px;
  margin-left: 100px;
  padding: 10px 20px;
  border: none;
  background-color: #da8ee7;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.sidebar {
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  width: 250px;
  background-color: #222222;
  box-shadow: -2px 0 5px rgba(0,0,0,0.5);
  transform: translateX(100%);
  transition: transform 0.3s ease;
  padding: 20px;
  overflow-y: auto;
}

.sidebar.open {
  transform: translateX(0);
}

@media (max-width: 768px) {
  .comic-viewer-container.with-sidebar {
    margin-right: 100%;
  }

  .sidebar {
    width: 100%;
  }
} */

.comic-viewer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: margin-right 0.3s ease;
}

.comic-viewer-container.with-sidebar {
  margin-right: 250px;
}

.double-page-view, .multiple-pages-view, .long-strip-view, .full-width-view {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  justify-content: center;
}

.double-page {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.double-page img, .multiple-pages-view img, .long-strip-view img {
  width: 45%;
  height: auto;
}

.long-strip-view {
  flex-direction: column;
  align-items: center;
  gap: 3px;
}

.long-strip-page img, .full-width-page img {
  width: 100%;
  height: auto;
}

.full-width-view {
  display: flex;
  flex-wrap: wrap;
  gap: 3px;
  width: 100%;
  justify-content: center;
}

.full-width-page {
  width: 100%;
  margin-bottom: 1px;
}

.view-mode-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px; /* Adjusted for more space above buttons */
}

.view-mode-buttons button {
  padding: 10px 20px;
  border: none;
  background-color: #da8ee7;
  color: black;
  font-weight: 500;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.view-mode-buttons button:hover {
  background-color: #ab2ec1;
}

.toggle-sidebar {
  position: fixed;
  top: 60px;
  right: 20px;
  padding: 10px 20px;
  border: none;
  background-color: #da8ee7;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease, right 0.3s ease;
  z-index: 2;
}

.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 250px;
  background-color: #222222;
  box-shadow: -2px 0 5px rgba(0,0,0,0.5);
  transform: translateX(100%);
  transition: transform 0.3s ease;
  padding: 20px;
  overflow-y: auto;
  z-index: 1;
}

.sidebar.open {
  transform: translateX(0);
}

.sidebar.open + .toggle-sidebar {
  right: 250px;
}

@media (max-width: 768px) {
  .comic-viewer-container.with-sidebar {
    margin-right: 100%;
  }

  .sidebar {
    width: 100%;
  }

  .sidebar.open + .toggle-sidebar {
    right: 100%;
  }
}

.page-dropdown-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 10px; /* Added margin for spacing within sidebar */
}

.page-dropdown-container label {
  margin-bottom: 10px; /* Adjusted for better spacing */
  font-size: 16px;
  color: white; /* Adjusted for sidebar dark background */
}

.page-dropdown {
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px; /* Adjusted for spacing between elements */
  width: 100%;
}

#current-page {
  font-size: 16px;
  color: white; /* Adjusted for sidebar dark background */
}

.title{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.image-gallery-icon{
  z-index : 1 !important
}