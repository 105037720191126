@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
.divider:after,
.divider:before {
content: "";
flex: 1;
height: 1px;
background: #eee;
}
.admin_login_bg{
    background-color: #EBEBEB;
}
.admin_login_form1{
    background-color: #FFFFFF;
    padding: 80px;
    font-family: raleway;
    border-radius: 20px;
}
.admin_login_submitbtn{
    width: 40%;
    text-align: start;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #333333;
    border-radius: 50px;
    height: 50px;
    color: whitesmoke;
    font-weight: 600;
    transition: .2s all ease-in-out;
}
.admin_login_submitbtn:hover{
    background-color: #57B846;
    color: whitesmoke;
}
.admin_login_submit_btnContainer{
    display: flex;
    justify-content: start;
}