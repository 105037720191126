/* @media (max-width: 600px ) {
    .top10item{
        width: 99% !important;
    }
    .top10item:nth-child(even){
        display: none !important;
    }
    .top10{
        display: block  !important;
    }
} */

.top.card-img-top {
    height: 200px !important;
}