.card {
    margin: 0 10px;
    background: rgba(0,0,0,0);

  }
  .card:hover{
    border: 0px;
  }
  .card-img-top {
    height: 360px;
    object-fit: fill;
  }
  .card-body  {
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: justify;
    overflow-y: auto;
    padding: 10px;
    display: none; /* Hide card body initially */
  }
  .card:hover .card-body  {
    display: flex; /* Show card body on hover */
  }
  .slick-slide {
    padding: 0 10px;
  }
  .comicName{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: calc(4* 1.5em);
    box-sizing: border-box;
    line-height: 1.5em;
  }

  .card-text::-webkit-scrollbar {
    display: none;
  }