@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
.user_side_nav_bar body {
    /* padding-bottom: 30px; */
    position: relative;
    min-height: 100%;
  }
  
  .user_side_nav_bar a {
    transition: background 0.2s, color 0.2s;
  }
  .user_side_nav_bar a:hover,
  a:focus {
    text-decoration: none;
  }
  
  .user_side_nav_bar  #wrapper {
    padding-left: 0;
    transition: all 0.5s ease;
    position: relative;
  }
  
  .user_side_nav_bar #sidebar-wrapper {
    z-index: 1000;
    position: fixed;
    left: 250px;
    width: 0;
    height: 100%;
    margin-left: -250px;
    overflow-y: auto;
    overflow-x: hidden;
    background: #222;
    transition: all 0.5s ease;
  }
  
  .user_side_nav_bar  #wrapper.toggled #sidebar-wrapper {
    width: 180px;
  }
  
  .user_side_nav_bar .user-sidebar-brand {
    position: absolute;
    top: 0;
    width: 250px;
    text-align: center;
  }
  .user_side_nav_bar .user-sidebar-brand h2 {
    margin: 0;
    font-weight: 600;
    font-size: 24px;
    color: #fff;
    margin: 0px !important;
  }
  
  .user_side_nav_bar  .sidebar-nav {
    position: absolute;
    top: 75px;
    width: 250px;
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .user_side_nav_bar  .sidebar-nav > li {
    text-indent: 10px;
    line-height: 42px;
  }
  .user_side_nav_bar .sidebar-nav > li a {
    display: flex;
    text-decoration: none;
    color: #ffffff;
    font-weight: 200;
    font-size: 18px;
    padding: .1vw;
    font-family: outfit;

  }
  .user_side_nav_bar .sidebar-nav > li > a:hover,
  .sidebar-nav > a {
    text-decoration: none;
    color: black;
    background: #da8ee7;
    font-weight: 500;
    border-top-left-radius: 2.11vw;
    border-bottom-left-radius: 2.11vw;
    padding: .1vw;
  }
  /* round the nav bar items corner */
  /* .sidebar-nav > li:hover > a::before{
    content: '';
    position: absolute;
    right: 0;
    margin-top: -3.1vw;
    width: 50px;
    height: 50px;
    background-color: transparent;
    border-radius: 50%;
    box-shadow: 35px 35px 0 10px #fafafa;
    pointer-events: none;
  }
  .sidebar-nav > li:hover > a::after{
    content: '';
    position: absolute;
    right: 0;
    margin-top: 2.5vw;
    width: 50px;
    height: 50px;
    background-color: transparent;
    border-radius: 50%;
    box-shadow: 35px -35px 0 10px #fafafa;
    pointer-events: none;
  } */
  .user_side_nav_bar  .sidebar-nav > li > a i.fa {
    font-size: 24px;
    width: 60px;
  }
  
  .user_side_nav_bar #navbar-wrapper {
      width: 100%;
      position: absolute;
      z-index: 2;
      
    }
    .user_side_nav_bar   .nnav1{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 120%;
  height: 6vh;
  background-color: #222222;
  margin: 0;
}

.user_side_nav_bar  #wrapper.toggled #navbar-wrapper {
      position: absolute;
      margin-right: -250px;
  }
  .user_side_nav_bar #navbar-wrapper .navbar {
    border-width: 0 0 0 0;
    background-color: transparent !important;
    font-size: 24px;
    margin-bottom: 0;
    border-radius: 0;
    position: absolute;
    width: 100%;
    margin: 0;
    padding: 0;
    /* margin-bottom: 5vw; */
  }
  .user_side_nav_bar  #navbar-wrapper .navbar a {
    color: #757575;
  }
  .user_side_nav_bar #navbar-wrapper .navbar a:hover {
    color: #da8ee7;
  }
  
  .user_side_nav_bar #content-wrapper {
    width: 100%;
    position: absolute;
    /* padding: 15px; */
    top: 100px;
    /* background-color: #ECEDF0; */
    /* height: 100%; */
    /* overflow-x: hidden; */
  }
  .user_side_nav_bar #wrapper.toggled #content-wrapper {
    position: absolute;
    margin-right: -250px;
  }
  
  @media (min-width: 992px) {
    .user_side_nav_bar #wrapper {
      padding-left: 180px;
    }
    
    .user_side_nav_bar  #wrapper.toggled {
      padding-left: 60px;
    }
  
    .user_side_nav_bar #sidebar-wrapper {
      width: 180px;
    }
    
    .user_side_nav_bar #wrapper.toggled #sidebar-wrapper {
      width: 0px;
    }
    
    .user_side_nav_bar #wrapper.toggled #navbar-wrapper {
      position: absolute;
      margin-right: -190px;
  }
    
  .user_side_nav_bar #wrapper.toggled #content-wrapper {
      position: absolute;
      margin-right: -190px;
    }
  
    .user_side_nav_bar #navbar-wrapper {
      position: relative;
    }
  
    .user_side_nav_bar #wrapper.toggled {
      padding-left: 0px;
    }
  
    .user_side_nav_bar #content-wrapper {
      position: relative;
      top: 0;
      /* overflow: hidden; */
    }
  
    .user_side_nav_bar #wrapper.toggled #navbar-wrapper,
    #wrapper.toggled #content-wrapper {
      position: relative;
      margin-right: 60px;
    }
  }
  
  @media (min-width: 768px) and (max-width: 991px) {
    .user_side_nav_bar #wrapper {
      padding-left: 0px;
    }
  
    .user_side_nav_bar #sidebar-wrapper {
      width: 0px;
    }
    
    .user_side_nav_bar #wrapper.toggled #navbar-wrapper {
      position: absolute;
      margin-right: -250px;
  }
    
  .user_side_nav_bar #wrapper.toggled #content-wrapper {
      position: absolute;
      margin-right: -250px;
    }
  
    .user_side_nav_bar #navbar-wrapper {
      position: relative;
    }
  
    .user_side_nav_bar #wrapper.toggled {
      padding-left: 180px;
    }
  
    .user_side_nav_bar #content-wrapper {
      position: relative;
      top: 0;
    }
  
    .user_side_nav_bar #wrapper.toggled #navbar-wrapper,
    #wrapper.toggled #content-wrapper {
      position: relative;
      margin-right: 250px;
    }
  }
  
  @media (max-width: 767px) {
    .user_side_nav_bar #wrapper {
      padding-left: 0;
    }
  
    .user_side_nav_bar #sidebar-wrapper {
      width: 0;
    }
  
    .user_side_nav_bar #wrapper.toggled #sidebar-wrapper {
      width: 180px;
    }
    .user_side_nav_bar #wrapper.toggled #navbar-wrapper {
      position: absolute;
      margin-right: -250px;
    }
  
    .user_side_nav_bar #wrapper.toggled #content-wrapper {
      position: absolute;
      margin-right: -250px;
    }
  
    .user_side_nav_bar #navbar-wrapper {
      position: relative;
    }
  
    .user_side_nav_bar #wrapper.toggled {
      padding-left: 180px;
    }
  
    .user_side_nav_bar #content-wrapper {
      position: relative;
      top: 0;
    }
  
    .user_side_nav_bar  #wrapper.toggled #navbar-wrapper,
    #wrapper.toggled #content-wrapper {
      position: relative;
      margin-right: 250px;
    }
  }
  /* .middle_component{
    background-color: black;
    width: 100%;
    height: 40vw;
  }
  .tttxtt1{
    font-size: 10vw;
    color: wheat;
    text-align: center;
  } */
  .user_side_nav_bar .btn.dd1.dropdown-toggle::after {
    display: none;
}
.user_side_nav_bar .dd1.dropdown-item:hover{
  background-color: black;
  color: red;
}
.user_side_nav_bar .dd1.dropdown-item{
  display: flex;

}
.user_side_nav_bar .dd1.dropdown-item:hover .dd1ttxt1{
  /* background-color: black; */
  color: whitesmoke;
}
.user_side_nav_bar .dd1ttxt1{
  color: black;
}

.btn_top_scroll {
  background-color: #da8ee7;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  position: fixed;
  bottom: 20px;
  width: 50px;
  height: 50px;
  right: 20px;
  z-index: 1000;
  width: 50px; /* Adjust width and height to ensure a perfect circle */
  height: 50px;
}

.btn_top_scroll:hover{
  background-color: #da8ee7;
  color: white;
}

@media (max-width:500px) {
  .btn_top_scroll{
    left: 86%;
  } 
  .navIcon{
    gap: 10px !important;
    right: 40px !important;
  }
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  background: #282c34;
  color: white;
}