.hero {
  /* margin-top: 3rem; */
  width: 100%;
  background-color: beige;
  height: 150vh;
  /* background-size: auto !important; */
  /* background-position: -180px -198px !important; */
  filter: blur(10px) brightness(0.6) !important;
  position: absolute;
  z-index: 0;
}
.outer_main {
  display: flex;
}
.outer_main_image {
  top: 8%;
  z-index: 1;
  position: absolute;
  left: 5%;
  /* width: 30%; */
  /* border-radius: 20px !important; */
}
.comic_content_container {
  width: 100%;
  max-height: 100%;
  min-height: 120vh;
  padding: 10px;
  background-color: #1f2022ee;
  position: absolute;
  z-index: 0;
  top: 310px;
}

.comic_title_container {
  width: 70%;
  height: 200px;
  /* background-color: aliceblue; */
  position: absolute;
  z-index: 1;
  top: 8%;
  left: 30%;
  display: flex;
  padding: 0;
  flex-direction: column;
}
.comic_title1 {
  font-family: poppins;
  /* font-size: 300%; */
  font-size: 2em;
  color: white;
  font-weight: 800;
  text-overflow: clip;
  margin: 0;
}
.comic_title2 {
  /* font-family: poppins;
    font-size: 1.6em;
    color: white;
    font-weight: 400;
    text-overflow: clip;
    margin: 0; */
  font-size: 1.2em;
  color: white;
  font-family: poppins;
  font-weight: 400;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.7;
  max-height: calc(4 * 1.5em); /* 4 lines times the line-height */
  padding: 11px; /* Optional: Adjust based on your design */
  box-sizing: border-box;
  text-align: justify;
  min-height: 80px;
}
.comic_title3 {
  font-size: 1.2em;
  color: white;
  font-family: poppins;
  font-weight: 400;
  padding-top: 10px;
  min-height: 45px;
}

.saveButton {
  background-color: #dd68f2;
  color: white;
  border: 0px solid;
  justify-content: center;
  border-radius: 5px;
  font-size: 1rem;
  width: 15rem;
  height: 3rem;
  font-weight: 500;
}

.save_buttons_container {
  margin-left: 20% !important;
  gap: 10px !important;
  margin-bottom: 50px !important;
}

@media (max-width: 1280px) {
  .save_buttons_container {
    margin-left: 28% !important;
  }
}

@media (max-width: 820px) {
  .comic_title_container {
    left: 30%;
    width: auto;
  }
  /* .comic_title1 {
    font-size: 2em;
    padding: 12px;
  } */
  .comic_title2 {
    font-size: 0.8em;
    /* min-height: 60px; */
  }
  .comic_title3 {
    font-size: 0.8em !important;
    min-height: 60px;
  }
  .genres .genre_title{
    font-size: 0.7em !important;
  }
  .outer_main_image {
    width: 30%;
  }
  .outer_main_image img {
    width: 66%;
    height: 100%;
  }
  .savebutton {
    margin: 0;
  }
  .save_buttons_container {
    text-align: start;
    /* padding-right: 265px; */
    gap: 10px;
    margin-left: 0% !important;
  }
  .comic_content_container {
    padding: 5px;
    top: 324px;
  }
}
@media (min-width: 520px) {
  .comic_title1 {
    font-size: 2em;
    color: white;
    font-family: poppins;
    font-weight: 800;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.7;
    max-height: calc(1 * 2em); /* 4 lines times the line-height */
    padding: 0px; /* Optional: Adjust based on your design */
    box-sizing: border-box;
    text-align: justify;
    min-height: 2em;
  }
  .genres {
    /* font-size: 1.2em; */
    color: white;
    font-family: poppins;
    font-weight: 400;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    /* -webkit-line-clamp: 3; */
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.7;
    /* max-height: calc(4 * 1.5em); 4 lines times the line-height */
    padding: 13px; /* Optional: Adjust based on your design */
    box-sizing: border-box;
    text-align: justify;
    min-height: 38px;
  }
}
@media (max-width: 520px) {
  .comic_title_container {
    left: 30%;
    width: 70%;
  }
  .comic_title1 {
    font-size: 1.3em;
  }
  .comic_title2 {
    display: none !important;
  }
  .outer_main_image {
    width: 30%;
  }
  .comic_content_container {
    padding: 5px;
    top: 265px;
  }
  .comic_content_container {
    padding: 5px;
  }
  .save_buttons_container {
    width: 100%;
    margin-left: 12px !important;
  }
  .genres {
    min-height: 25px
  }
}
@media (max-width: 475px) {
  .genres {
    min-height: 27px;
    font-size: 1.1em;
  }
}
@media (max-width: 380px) {
  .comic_title_container {
    left: 32%;
    width: 68%;
  }

  .comic_title1 {
    font-size: 1.2em;
  }

  .outer_main_image {
    width: 30%;
  }
  .saveButton {
    background-color: #ff6740;
    color: white;
    border: 0px solid;
    justify-content: center;
    border-radius: 5px;
    font-size: 13px;
    width: 15rem;
    height: 3rem;
    font-weight: 500;
  }
  .save_buttons_container {
    width:100%;
  }
}
.savebutton {
  /* width: 8em;
    height: 1em; */
  font-size: 1em;
  padding: 5px 20px;
  color: white;
  border: 0px solid;
  border-radius: 5px;
  background: #ce6edf;
}
.reportButton {
  /* width: 8em;
    height: 1em; */
  font-size: 1em;
  padding: 5px 16px;
  color: white;
  border: 0px solid;
  border-radius: 5px;
  background: #4f4f4f;
  /* margin-left: 2%; */
  transition: filter 0.1s ease-out, color 0.1s ease-out, min-width 0.2s ease-out;
}
.reportButton :hover {
  opacity: 0.5;
}
#show_comic .newmodal .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  color: white !important;
}
#show_comic .newmodal .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  color: white !important;
}

#show_comic .css-p0rm37 {
  color: white !important;
}

#show_comic .css-1bp1ao6 {
  color: white !important;
}
#show_comic .css-1ald77x {
  color: white !important;
}

#show_comic .css-1ald77x.Mui-focused {
  color: white !important;
}
